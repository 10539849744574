import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import styles from "./LoginPhone.module.scss";
import { Button } from "@kursk/components/ui/Button/Button";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { authSlice, confirmSignInPhone, signInPhone } from "@common/redux/slices/auth.slice";
import { PhoneInput } from "@kursk/components/ui/PhoneInput/PhoneInput";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { View, ViewPanel } from "@kursk/components/Layout/AuthModal/View/View";
import { ConfirmView } from "@kursk/components/Layout/AuthModal/ConfirmView/ConfirmView";
import { prettifyPhoneWithCode } from "@common/utils/phones";
import { Input } from "@kursk/components/ui/Input/Input";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";
import { BackArrowIcon } from "@kursk/components/ui/icons";
import { SmartCaptcha } from "@yandex/smart-captcha";
import { getConfig } from "@root/config/config";

const config = getConfig();

export type LoginPhonePanels = 'loginPhone' | 'send_code'

interface Props {
  goToSignupForm: () => void;
  goBack: () => void;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const LoginFormPhone = ({ goToSignupForm, setIsOpen, goBack }: Props): ReactElement => {

  const dispatchThunk = useDispatchThunk();
  const dispatch = useDispatch();
  const { signInResponse } = useSelectSlice(authSlice);
  const [activePanel, setActivePanel] = useState<LoginPhonePanels>('loginPhone');
  const [confirmCode, setConfirmCode] = useState<string>('');
  const [validatePhone, setValidatePhone] = useState<string>('');
  const [signInError, setSignInError] = useState<string>('');
  const [captchaValue, setCaptchaValue] = useState<string | undefined>();
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const { handleSubmit, register, formState: { errors, isDirty, isValid }, getValues, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(Yup.object({
      phone: Yup.string().required('Неверно указан номер телефона').min(13, 'Неверно указан номер телефона'),
    })),
    defaultValues: {
      phone: '',
    }
  });

  useEffect(() => {
    if (signInResponse?.errorName === 'NotFoundError') {
      setSignInError('Данный номер телефона не зарегистрирован');
    } else if (signInResponse?.confirmPhoneError) {
      setSignInError(signInResponse?.confirmPhoneError.userMessage);
    } else {
      setSignInError('');
    }
  }, [signInResponse]);

  const submit = async () => {
    const phone = getValues().phone.replace(/\s/g, "");
    setValidatePhone(phone);
    const res = await dispatchThunk(signInPhone({ phone, token: captchaValue }))
    if (!res.error) {
      setActivePanel('send_code');
    } else {
      if (res.payload?.response?.data?.errorName === 'InvalidSmartCaptchaError') {
        handleResetCaptcha();
        if (activePanel === 'send_code') {
          setActivePanel('loginPhone');
        }
      }
    }
  };

  const confirmPhone = async () => {
    const res = await dispatchThunk(confirmSignInPhone({
      code: confirmCode,
      id: signInResponse.id
    }));
    if (!res.error) {
      dispatch(notificationPush({ content: 'Вы успешно вошли в систему', type: 'success' }));
      setIsOpen(false);
    }
  };

  const handleResetCaptcha = () => {
    setResetCaptcha((prev) => prev + 1);
    setCaptchaValue(undefined);
  };

  return (
    <>
      <div className={styles.backArrow} onClick={goBack}>
        <BackArrowIcon/>
      </div>
      <View activePanel={activePanel}>

        <ViewPanel panelId="loginPhone">
          <form onSubmit={handleSubmit(submit)}>
            <h1 className={styles.header}>Авторизация</h1>
            <PhoneInput
              errors={errors}
              control={control}
              register={register}
              customError={signInError}
              onChange={() => setSignInError('')}
            />
            {config.yandexCaptchaKey ? (
              <div className={styles.captcha}>
                <SmartCaptcha
                  key={resetCaptcha}
                  sitekey={config.yandexCaptchaKey}
                  language="ru"
                  onSuccess={setCaptchaValue}
                  onTokenExpired={handleResetCaptcha}
                />
              </div>
            ) : null}
            <Button
              text='Продолжить'
              type="submit"
              className={styles.submit}
              disabled={!captchaValue || !isDirty || !isValid}
              buttonType='greenPrimary'
            />
            <Button
              text='ЗАРЕГИСТРИРОВАТЬСЯ'
              type="button"
              className={styles.register}
              onClick={goToSignupForm}
              buttonType='ghostButton'
            />
          </form>
        </ViewPanel>

        <ViewPanel panelId="send_code">
          {validatePhone && (
            <ConfirmView
              title='Подтверждение'
              description={`
          На номер ${prettifyPhoneWithCode(validatePhone)} вам поступит звонок.
          Введите в поле ниже последние 4 цифры номера
          `}
              onSubmit={submit}
              resendText='Запросить код повторно'
              countText='Повторный запрос кода возможен через'
              footer={<Button
                text='ВОЙТИ'
                onClick={confirmPhone}
                type="button"
                className={styles.button}
              />}
            >
              <>
                <label className={styles.label}>
                  Код подтверждения
                </label>
                <div className={styles.input_wrapper}>
                  <Input
                    type="text"
                    name='code'
                    onChange={(e) => {
                      setSignInError('');
                      setConfirmCode(e.target.value);
                    }}
                    error={signInError}/>
                </div>
              </>
            </ConfirmView>
          )}
        </ViewPanel>

      </View>
    </>
  );
};
