import React, { ReactElement } from 'react';
import { useForm } from "react-hook-form";
import { BaseSignUpForm } from "@kursk/components/Layout/AuthModal/SignUpForm/Blocks/BaseSignUpForm";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from '../SignUpForm.module.scss';
import * as Yup from "yup";
import { PhoneInput } from "@kursk/components/ui/PhoneInput/PhoneInput";
import { SignUpPhoneFields } from "@common/redux/types/auth";

interface Props {
  goToLoginForm: () => void;
  onSubmit: (values: SignUpPhoneFields) => Promise<any>;
  registrationError?: string;
  onChange: (value: string) => void;
  captchaValue: string;
  setCaptchaValue: (value: string) => void;
  resetCaptcha: number;
  handleResetCaptcha: (value: string) => void;
}

export const SignUpTel = ({
  goToLoginForm,
  onSubmit,
  registrationError,
  onChange,
  captchaValue,
  setCaptchaValue,
  resetCaptcha,
  handleResetCaptcha,
}: Props): ReactElement => {
  const { handleSubmit, register, formState: {
    errors, isDirty, isValid }, getValues, control
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(Yup.object({
      phone: Yup.string().required('Неверно указан номер телефона').min(13, 'Неверно указан номер телефона'),
      name: Yup.string().required('Это поле необходимо заполнить'),
      gender: Yup.string().required(),
      agree: Yup.boolean().test({
        test: agree => !!agree,
        message: 'Необходимо подтвердить согласие с условиями пользовательского соглашения'
      }),
    })),
    defaultValues: {
      phone: '',
      name: '',
      gender: 'none',
      agree: false,
    }
  });

  const onFormSubmit = async (captchaValue: string) => {
    const values = getValues();
    const name = values.name;
    const phone = values.phone.replace(/\s/g, "");
    const gender = values.gender;
    await onSubmit({ name, phone, gender, token: captchaValue });
  };

  return (
    <>
      <BaseSignUpForm
        onSubmit={onFormSubmit}
        handleSubmit={handleSubmit}
        register={register}
        genderControl={control}
        errors={errors}
        disabledRegisterBtn={!isDirty || !isValid}
        goToLoginForm={goToLoginForm}
        isPhoneSignUpForm
        captchaValue={captchaValue}
        setCaptchaValue={setCaptchaValue}
        resetCaptcha={resetCaptcha}
        handleResetCaptcha={handleResetCaptcha}
      >
        <div className={styles.phoneWrap}>
          <PhoneInput
            errors={errors}
            customError={registrationError}
            control={control}
            register={register}
            onChange={(value) => onChange(value)}
          />
        </div>
      </BaseSignUpForm>
    </>
  )
};
